<template>
  <v-app>
    <v-content class="white home">
      <hero />

      <security />
      
      <codigoQR />

      <custom />
      
      <contentLanding />
      
      <saving />
      
      <contact />
      
      <footerLanding />

    </v-content>
  </v-app>
</template>

<script>
  export default {
    name: 'BaseLanding',
    components:{
      hero: () => import('@/views/landing/Home/header'),
      security: () => import('@/views/landing/Home/Security'),
      codigoQR: () => import('@/views/landing/Home/CodigoQR'),
      custom: () => import('@/views/landing/Home/Custom'),
      contentLanding: () => import('@/views/landing/Home/Content'),
      saving: () => import('@/views/landing/Home/Saving'),
      contact: () => import('@/views/landing/Home/Contact'),
      footerLanding: () => import('@/views/landing/Home/Footer'),
    },
    data: () => ({ 
      sections: [] 
    }),
  }
</script>
<style lang="sass">
.home p, .home span, .home a
  font-family: Roboto, sans-serif
</style>